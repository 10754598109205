<template>
  <Symbol hidden />
  <router-view />
  <!-- <router-view :screenWidth="screenWidth" :screenHeight="screenHeight" :scrollTop="scrollTop" /> -->
</template>

<script>
import Symbol from '@/components/symbol/Symbol.vue'
export default {
  name: 'App',
  components: {
    Symbol
  },
  data() {
    return {
      screenWidth: 0, //document.documentElement.clientWidth, //实时屏幕宽度
      screenHeight: 0, //document.documentElement.clientHeight, //实时屏幕高度
      scrollTop: 0 //document.body.scrollTop,
    }
  },
  created() {
    this.$socket.open()
    console.log(this.$route)
  },
  watch: {},
  setup() {
    let flag = navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i)
    if (flag) {
      let url = window.frames.location.href
      // console.log(url,url.includes('/#/beseller?code='))
      if (url.includes('/#/beseller?sid=')) {
        let sort = url.indexOf('sid=')
        let code = url.substring(sort)
        let url_str = `https://wap.${window.location.host}/#/pages/beseller/beseller?${code}`
        // console.log(url, sort, url_str)
        window.location.href = url_str
      } else if (url.includes('/#/index?code=')) {
        let sort = url.indexOf('code=')
        let code = url.substring(sort)
        let url_str = `https://wap.${window.location.host}/#/pages/index/index?${code}`
        // console.log(url, sort, url_str)
        window.location.href = url_str
      } else {
        let url = `https://wap.${window.location.host}`
        window.location.href = url
      }
    }
  },
  mounted() {
    if (this._isMobile()) {
      // console.log('mobile')
    } else {
      // console.log('Windows')
    }
  },
  methods: {
    _isMobile() {
      let flag = navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i)
      return flag
    }
  }
}
</script>
<style scoped>
@import url('@/static/lang/lang.css');
</style>
<style>
#app {
  /* background-color: #f5f5f5; */
}

._load {
  margin: 30px 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

._dcc {
  display: flex;
  align-items: center;
  justify-content: center;
}

::-webkit-scrollbar {
  /*隐藏滚轮*/
  /* display: none; */
}

.lev-bg {
  gap: 2px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  height: 15px;
}

.over-text {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  vertical-align: inherit;
}

a:hover {
  color: initial;
}

.swiper-slide-active {
  opacity: 1 !important;
}

.ant-radio-checked .ant-radio-inner {
  border-color: #000000;
}

.ant-radio-inner::after {
  background-color: #000;
}

.ant-radio-wrapper:hover .ant-radio,
.ant-radio:hover .ant-radio-inner,
.ant-radio-input:focus + .ant-radio-inner {
  border-color: #000000;
}

.app-main {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1;
  visibility: hidden;
  opacity: 0;
  transform: translateY(10px);
  transition: opacity 0.25s ease, transform 0.15s ease, visibility 0.15s;
}

.ng-mob {
  position: relative;
}
.ng-mob:hover .app-main {
  visibility: visible;
  opacity: 1;
  transform: translateY(0);
}
.app-box {
  position: absolute;
  display: flex;
  gap: 10px;
  background-color: #fff;
  border: 1px solid #e3e3e3;
  box-shadow: 0 0 5px 1px rgba(0, 0, 0, 0.11);
  /* padding: 10px; */
  border-radius: 5px;
}

#webpack-dev-server-client-overlay {
  display: none !important;
}
</style>
