<template>
  <div v-if="isShow">
    <div class="zoom-box" v-if="!showRedBag && redbag?.id">
      <img class="zoom-animation zoom-re" src="../../static/redbag/redbag_icon.png" alt="" @click="onOnce" />
    </div>
    <div class="rb-bg rb-ma" v-if="showRedBag" @click="onClose">
      <div class="zoomred-animation rb-zzbg">
        <img src="../../static/redbag/bg.png" alt="" />
      </div>

      <div class="rb-m rb-ma" @click.stop="() => {}">
        <div class="rb-txxx-close">
          <close-circle-outlined @click="onClose" class="rbclose" style="font-size: 22px; cursor: pointer" />
        </div>
        <div class="zoomred-animation" style="position: relative">
          <div class="rb-jebox">
            <div class="rb-sjhbbt">{{ redbag.name }}</div>
            <div class="rb-amount">${{ redbag.amount }}</div>
            <!-- <div class="rb-jetext">商家红包，开店立即优惠</div> -->
          </div>
          <div class="rb-img">
            <img src="../../static/redbag/redbag.png" alt="" />
          </div>
        </div>

        <div style="margin-top: 10px" v-if="!isLogin">
          <span style="color: #ffffff">{{ $t('_sjdhb._hmyzh') }}? </span>
          <a style="color: #ffe000" @click="onSignup">{{ $t('_sjdhb._zcblq') }}>></a>
        </div>

        <div class="rb-txxx" v-if="isSignup">
          <div class="rb-ibox">
            <div class="rb-kjbt">
              <h2>{{ $t('_sjdhb._zcblq') }}</h2>
            </div>
            <div class="rb-txxx-close">
              <close-circle-outlined @click="onCloseSignup" class="rbclose" style="font-size: 22px; cursor: pointer" />
            </div>
            <div class="rb-srk">
              <div class="rb-tit">{{ $t('_sjdhb._dlyx') }}:</div>
              <div class="rb-srkk">
                <input v-model="params.email" type="text" :placeholder="$t('_sjdhb._sryx')" />
              </div>
              <div style="width: 15%"></div>
            </div>
            <div class="rb-srk">
              <div class="rb-tit">{{ $t('_sjdhb._szmm') }}:</div>
              <div class="rb-srkk">
                <input v-model="params.password" type="password" :placeholder="$t('_sjdhb._srmm')" />
              </div>
              <div style="width: 15%"></div>
            </div>
            <div class="rb-srk">
              <div class="rb-tit">{{ $t('_sjdhb._lxdh') }}:</div>
              <div style="width: 75%; display: flex; gap: 5px">
                <!-- <div>
                  <a-select v-model:value="params.country" style="width: 80px" placeholder="country">
                    <a-select-option value="+1">+1</a-select-option>
                    <a-select-option value="+41">+41</a-select-option>
                  </a-select>
                </div> -->
                <div class="rb-srkk">
                  <input v-model="params.mobile" type="text" :placeholder="$t('_sjdhb._srlxdh')" />
                </div>
              </div>
              <div style="width: 15%"></div>
            </div>
            <div class="rb-srk">
              <div class="rb-tit">{{ $t('_sjdhb._yzm') }}:</div>
              <div style="width: 75%; display: flex; align-items: center">
                <div class="rb-srkk" style="width: 50%">
                  <input v-model="params.code" type="text" :placeholder="$t('_sjdhb._sryxyzm')" />
                </div>
                <div style="width: 50%; text-align: center">
                  <div style="cursor: pointer; text-decoration: underline" @click="onSendCode()">{{ codeBtnText }}</div>
                </div>
              </div>
              <div style="width: 15%"></div>
            </div>
          </div>
        </div>

        <div class="rb-bbox" style="margin-top: 25px">
          <div class="rb-btn" @click="onReceive" v-if="isLogin">{{ $t('_sjdhb._ljlq') }}</div>
          <div class="rb-btn" @click="onSignupReceive" v-else-if="isSignup">{{ $t('_sjdhb._ljlq') }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { CloseCircleOutlined } from '@ant-design/icons-vue'
import { countdown } from '@/utils/tools'
export default {
  name: 'RedBagVue',
  components: {
    CloseCircleOutlined
  },
  data() {
    return {
      isShow: false, //整个组件显示
      showRedBag: false, //红包区域显示
      isSignup: false, //快捷注册显示

      //是否登录
      isLogin: false,
      sp_info: {},

      //红包
      redbag: {},

      //注册参数
      params: {
        redbag_code: '',
        email: '',
        password: '',
        country: '+1',
        mobile: '',
        code: ''
      },

      //发送验证码
      hasSend: true,
      codeBtnText: this.$t('base.send.code')
    }
  },
  created() {},
  methods: {
    show(code) {
      //获取红包
      this.isShow = true
      this.params.redbag_code = code
      this.getRedbag(code)
      this.tokenStatus()
    },
    onOnce() {
      this.showRedBag = true
      this.tokenStatus()
    },
    /**
     * 登录成功
     */
    tokenStatus() {
      this.isLogin = this.$common.isLogin()
      this.sp_info = this.$common.getInfo()
    },
    getRedbag(code) {
      let that = this
      this.$api.redbag.getRedbag({ code: code }).then((res) => {
        that.redbag = res.data
        if (Object.keys(that.redbag).length > 0) {
          that.showRedBag = true
        }
      })
    },
    onReceive() {
      this.$message.loading({ content: 'receive...', key: 'msg' })
      let that = this
      this.$api.redbag.receive({ id: this.redbag.id }).then((res) => {
        that.$message.success({ content: res.msg, key: 'msg' })
        that.onClose()
      })
    },

    onSignupReceive() {
      this.$message.loading({ content: 'receive...', key: 'msg' })
      let that = this
      this.$api.redbag.signupReceive(this.params).then((res) => {
        that.$message.success({ content: res.msg, key: 'msg' })
        // that.onClose()
        setTimeout(() => {
          that.$router.push({ name: 'storeSignin' })
        }, 1000)
      })
    },
    /**
     * 发送验证码
     */
    onSendCode() {
      if (!this.hasSend) {
        return
      }
      let email = this.$common.rmSpaces(this.params.email)
      let key = this.$md5(email)

      this.$message.loading({ content: 'loading...', key: 'msg' })

      let that = this
      this.$api.code
        .sendCode({
          email: email,
          scene: 'signup'
        })
        .then((res) => {
          that.$message.success({
            content: this.$t('base.send.success') /* + ",code:" + res.data.code*/,
            key: 'msg'
          })
          countdown.start(key, res.data.exp)
          countdown.run(key, that.CallTime)
        })
    },
    /**
     * 倒计时回调
     * @param {*} time
     */
    CallTime(time) {
      console.log(time)
      if (time == 0) {
        this.hasSend = true
        this.codeBtnText = this.$t('base.send.code')
      } else {
        this.hasSend = false
        this.codeBtnText = this.$t('base.send.time', {
          time: time
        })
      }
    },

    onClose() {
      this.showRedBag = false
      this.isSignup = false
    },
    onSignup() {
      this.isSignup = true
    },
    onCloseSignup() {
      this.isSignup = false
    }
  }
}
</script>

<style scoped src="../../static/redbag/redbag.css"></style>
<style scoped>
.rb-srk >>> .ant-select-selector {
  height: 35px !important;
}
.rb-srk >>> .ant-select-selection-item {
  line-height: 35px !important;
}

.zoom-box {
  position: fixed;
  bottom: 5%;
  right: 5%;
  z-index: 9;
}

/* 定义动画名称和关键帧 */
@keyframes zoom {
  0% {
    transform: scale(1); /* 动画开始时的状态：正常大小 */
  }
  50% {
    transform: scale(1.2); /* 动画中间时刻的状态：放大10% */
  }
  100% {
    transform: scale(1); /* 动画结束时的状态：恢复正常大小 */
  }
}

/* 应用动画到元素上 */
.zoom-animation {
  animation: zoom 2s infinite; /* 动画名称，每次动画的持续时间，动画的循环次数 */
}

.zoom-re {
  width: 40px;
  cursor: pointer;
}

/* 定义动画名称和关键帧 */
@keyframes zoomred {
  0% {
    transform: scale(1); /* 动画开始时的状态：正常大小 */
  }
  50% {
    transform: scale(0.9); /* 动画中间时刻的状态：放大10% */
  }
  100% {
    transform: scale(1); /* 动画结束时的状态：恢复正常大小 */
  }
}

/* 应用动画到元素上 */
.zoomred-animation {
  animation: zoomred 2s infinite; /* 动画名称，每次动画的持续时间，动画的循环次数 */
}
</style>
