export default {
  'nav.account': 'Centro Pessoal',
  'nav.language': 'Chinês simplificado',
  'signup.success': 'Enviado com sucesso',

  //erro
  'contact.empty': 'As informações de contato não podem estar vazias',
  'phone.country.empty': 'O prefixo do telefone não pode estar vazio',
  'phone.empty': 'O telefone não pode estar vazio',
  'email.valid': 'Por favor, digite o e-mail correto',

  // base
  'base.success': 'sucesso',
  'base.save': 'Salvar',
  'base.slide': 'Puxe o controle deslizante para verificar',
  'base.send.code': 'Enviar código de verificação',
  'base.send.time': '({time}) reenviar código de verificação',
  'base.send.success': 'Enviado com sucesso',
  'base.view.more': 'Ver mais',
  'base.Language': 'Idioma',
  'base.previous': 'Página anterior',
  'base.next': 'Próxima página',
  'base.total.page': 'Total de {page} páginas',
  'base.go.to.page': 'Ir para a página',
  'base.go': 'ir para',
  'base.followers': 'Seguidores',
  'base.follow': 'seguir',
  'base.following': 'Seguido',
  'base.visit.store': 'Navegar na loja',
  'base.contact': 'Fale conosco',
  'base.delivery': 'Tempo estimado de entrega: 1-7 dias',
  'base.search': 'Pesquisar',
  'base.search.text': 'Digite a pesquisa por palavra-chave',
  'base.server': 'Atendimento ao cliente online',

  //índice
  'text.categories.title': 'Categoria do produto',
  'text.welcome': 'Bem-vindo às compras!',
  'text.wishlist': 'Lista de curtidas',
  'text.account': 'Central Pessoal',
  'text.orders': 'Pedidos',
  'text.messages': 'Mensagens',
  'text.suggest.title': 'Oferta exclusiva',
  'text.suggest.content': 'Apenas para novos membros premium globais!',
  'text.super.deals': 'Os melhores produtos, preços inacreditáveis.',

  //inscrever-se
  'signup.register': 'Registrar',
  'signup.signin': 'Login',
  'signup.store.signin': 'Login comercial',
  'signup.sign.out': 'Sair',
  'signup.email': 'Endereço de e-mail',
  'signup.password': 'Senha',
  'signup.qr.password': 'Confirmar senha',
  'signup.confirm.password': 'Confirmar senha',
  'signup.forgot.password': 'Esqueci a senha',
  'signup.invitation': 'Código de convite',
  'signup.char': 'Caractere',
  'signup.contains': 'Contém números, letras ou símbolos',
  'signup.qr.contains': 'As duas senhas são inconsistentes',
  'signup.create.account': 'Criar conta',
  'signup.agree': 'Criar uma conta, você concorda',
  'signup.member.agreement': 'Contrato de Associação',
  'cadastro.e': 'e',
  'signup.privacy.policy': 'Política de Privacidade',
  'signup.email.code': 'Verificação de e-mail',
  'signup.last.step': 'Última etapa',
  'signup.send.email': 'Por favor, digite o código de 4 dígitos enviado para {email}',
  'signup.modify.email': 'Modificar e-mail',
  'signup.verify.email': 'Verificar e-mail',
  'signup.have.store': 'Tem conta de comerciante',
  'signup.goto.signin': 'Login comercial',
  'signup.no.store': 'Sem conta de comerciante',
  'signup.goto.store': 'Cadastre-se como comerciante',
  'signup.next': 'Próximo',
  'signup.your.email': 'seu e-mail',
  'signup.code.text': 'Código de verificação',
  'signup.submit.signup': 'Inscreva-se agora',
  'signup.smrz': 'Autenticação de nome real',
  'signup.derb': 'Segundo passo',
  'signup.qsrxm': 'Por favor, digite o nome do documento',
  'signup.qsrhm': 'Por favor, digite o número de identificação',

  //esquecido
  'forgot.title': 'Redefinir senha',
  'forgot.btn.check': 'Verificar e-mail',
  'forgot.reset.now': 'Reiniciar agora',

  //loja
  'store.info.open': 'Aberto {y} semanas',

  // rodapé
  'footer.great.value': 'Grande valor',
  'footer.great.value.desc': 'Oferecemos preços competitivos para mais de 100 milhões de itens.',
  'footer.shopping': 'Compras globais',
  'footer.shopping.desc': 'Enviamos para mais de 200 países e regiões, e nosso site está disponível em 7 idiomas.',
  'footer.safe.payment': 'Pagamento Seguro',
  'footer.safe.payment.desc': 'Pague com os métodos de pagamento mais populares e seguros do mundo.',
  'footer.shop.with.trust': 'Compre com confiança',
  'footer.shop.with.trust.desc': 'Nossa política de proteção ao comprador cobre todo o seu processo de compra.',
  'footer.help.center': 'Central de Ajuda',
  'footer.help.center.desc': 'Assistência em qualquer clima para criar uma experiência de compra tranquila.',
  'footer.terms.conditions': 'Termos e Condições',
  'footer.return.policy': 'Política de Devolução',
  'footer.support.policy': 'Política de Suporte',
  'footer.privacy.policy': 'Política de Privacidade',
  'footer.be.seller': 'Torne-se um vendedor',
  'footer.apply.now': 'Aplicar agora',
  'footer.stay.connected': 'Mantenha-se conectado',

  'footer.about.us': 'Sobre nós',
  'footer.about.company': 'Perfil da Empresa',
  'footer.about.video': 'Introdução do vídeo',
  'footer.contact': 'Informações de contato',

  'footer.my.account': 'Minha conta',
  'footer.my.logout': 'Sair',
  'footer.my.order': 'Histórico de Pedidos',
  'footer.my.wish': 'Minha lista de desejos',
  'footer.my.join': 'Torne-se um parceiro membro',
  'footer.email': 'E-mail',
  'footer.gfemail': 'E-mail oficial',
  'footer.fwemail': 'E-mail de serviço',
  'footer.address': 'Endereço',

  'apply.success': 'Aplicativo bem-sucedido',
  'apply.success.desc': 'Aplicado com sucesso, vá para a conta de login',

  // categorias
  'category.title': 'Categorias relacionadas',
  'category.all': 'Todas as categorias',

  //detalhe
  'detail.store.home': 'Página inicial da loja',
  'detail.sale.items': 'Lista de produtos',
  'detail.recommend': 'Recomendo',
  'detail.orders': 'Vendas',
  'detail.quantity': 'Quantidade',
  'detail.pieces.available': 'Disponível',
  'detail.delivery': 'Entrega',
  'detail.free.shipping': 'Frete Grátis',
  'detail.estimated.delivery': 'Chegada estimada',
  'detail.days': 'dias',
  'detail.buy.now': 'Comprar',
  'detail.add.to.cart': 'Adicionar ao carrinho',
  'detail.buyer.protection': 'Proteção do Comprador',
  'detail.money.guarantee': 'Garantia de devolução do dinheiro',
  'detail.refund.desc': 'Receba um reembolso total se o item não for conforme descrito ou não for entregue',
  'detail.description': 'Descrição do produto',
  'detail.customer.reviews': 'Avaliações de clientes',
  'detail.specifications': 'Especificações do produto',
  'detail.top.selling.products': 'Produtos mais vendidos',
  'detail.recommended.for.you': 'Recomendado para você',
  'detail.sold': 'Vendas',
  'detail.recipt': 'Confirmar recebimento',
  'detail.recipt.title': 'Confirmar recebimento do pedido? ',
  'detail.receipt.content': 'Após a confirmação, o pedido de transação é concluído',
  'detail.comment': 'Comentário',
  'detail.refund.title': 'Confirmar pedido de reembolso',
  'detail.refund.content': 'Após a confirmação, o pedido será reembolsado',
  'detail.sqtk': 'Solicitar reembolso',

  //confirme
  'confirm.shipping.address': 'Endereço do destinatário',
  'confirm.change': 'Alterar',
  'confirm.payment.methods': 'Formas de pagamento',
  'confirm.summary': 'Liquidação',
  'confirm.total.item.costs': 'Total de itens',
  'confirm.total': 'Total',
  'confirm.checkout': 'Ir para checkout',
  'confirm.place.order': 'Faça um pedido agora',
  'confirm.pay.now': 'Pagar agora',
  'confirm.order.desc': "Após clicar em 'Fazer pedido', confirmo que li e confirmo",
  'confirm.order.policy': 'Todos os termos e políticas',
  'confirm.payment': 'Global Shopping garante a segurança de suas informações e pagamentos',

  // endereço
  'address.title': 'Endereço de recebimento',
  'endereço.padrão': 'padrão',
  'address.edit': 'Editar',
  'address.delete': 'Excluir',
  'address.new': 'Criar um novo endereço',
  'endereço.contato': 'Contato',
  'endereço.endereço': 'Endereço',
  'address.phone': 'Número de telefone',
  'address.set.default': 'Definir padrão',
  'address.confirm': 'Confirmar',
  'address.cancel': 'Cancelar',
  'address.del.title': 'Confirmar a exclusão do endereço?',
  'address.del.content': 'Tem certeza que deseja excluir este endereço de entrega?',

  'payment.method': 'Forma de pagamento',

  // Carrinho de compras
  'shopping.title': 'Carrinho de compras',
  'shopping.back.buy': 'Voltar às compras especiais',
  'shopping.start.shopping': 'Começar a comprar',

  // membro
  'member.account': 'Centro Pessoal',
  'member.overview': 'Visão geral',
  'member.orders': 'Pedidos',
  'member.payment': 'Minha Carteira',
  'member.address': 'Endereço de recebimento',
  'member.wishlist': 'Lista de curtidas',
  'member.followlist': 'Lista de seguidores',
  'member.message.center': 'Central de Mensagens',
  'member.setting': 'Configurações pessoais',
  'member.shop.info': 'Informações da loja',
  'member.shop.setting': 'Configurações da loja',
  'member.order.notify': 'Nova notificação de pedido',
  'member.order.tips': 'Ao enviar e-mails para o e-mail da conta do comerciante',

  //membro.ordem
  'member.order.title': 'Meu pedido',
  'member.order.view.all': 'Ver tudo',
  'member.order.all': 'Todos',
  'member.order.unpaid': 'não pago',
  'member.order.paid': 'Pago',
  'member.order.procurement': 'a ser comprado',
  'member.order.seller.paid': 'Comprado',
  'member.order.processing': 'a ser processado',
  'member.order.shipped': 'enviado',
  'member.order.completed': 'Concluído',
  'member.order.refund': 'Reembolsado',
  'member.order.all.time': 'todos',
  'member.order.empty': 'Nenhum pedido ainda',
  'membro.pedido.data': 'data',
  'member.order.purchase.date': 'Compra',
  'member.order.cpoy': 'Copiar',
  'membro.pedido.id': 'Pedido',
  'member.order.detail': 'Detalhes do pedido',
  'member.order.logistics': 'Informações de logística',

  // membro. pagamento
  'member.payment.title': 'Minha Carteira',
  'member.wallet.balance': 'saldo da carteira',
  'member.crypto.recharge': 'Recarregar',
  'member.crypto.withdrawal': 'Retirada',
  'member.crypto.bank': 'Cartão bancário',
  'member.wallet.record': 'registro de depósito de moeda',
  'member.bankcard.record': 'Registro do cartão bancário',
  'member.withdrawal.record': 'Registro de retirada',
  'member.income.record': 'registro de renda',
  'member.transaction.record': 'Registro de transação',
  'member.wallet.freeze': 'Congelar fundos',
  'member.wallet.profit': 'Ganhos estimados',

  //recarrega
  'recharge.currency': 'Moeda',
  'recharge.protocol': 'Protocolo',
  'recharge.qrcode': 'Código QR',
  'recharge.address': 'Endereço da carteira',
  'recharge.copy': 'Copiar endereço',
  'recharge.ok.text': 'Confirmar',
  'recharge.cancel.text': 'Cancelar',

  //banco
  'bank.recharge.title': 'Recarga de cartão bancário',
  'bank.title': 'Nome do banco',
  'bank.name': 'Nome do beneficiário',
  'bank.account': 'Conta de recebimento',
  'bank.routing': 'Número de roteamento do banco',
  'bank.code': 'Código do banco',
  'bank.bankname': 'Nome do banco',
  'bank.bankaddress': 'Endereço do banco',
  'bank.ok.text': 'Avançar',
  'bank.ok.prev': 'Etapa anterior',
  'bank.submit': 'Enviar agora',
  'bank.amount': 'Valor da recarga',
  'bank.amount.desc': 'Insira o valor da recarga',
  'banco.tipo': 'tipo',
  'bank.usd': 'USD',
  'bank.eur': 'Euro',
  'bank.recipt.number': 'Número do recibo',
  'bank.recipt.number.desc': 'Por favor, digite o número do recibo da transação',
  'bank.credential.picture': 'Imagem da credencial',
  'bank.credential.picture.desc': 'Favor enviar a imagem da credencial',
  'bank.remark': 'Observação',
  'bank.upload': 'Enviar fotos',
  'bank.text.title': 'Notas',
  'bank.text.t1': '1. Não precisa de comentários para transferência, deixe em branco',
  'bank.text.t2': '2. Selecione Hong Kong como o país da família de Hong Kong, não adicione China',
  'bank.text.t3': '3. Remessas dos EUA, durante a semana, remessas para a Europa antes das 15h, remessas disponíveis durante o horário de expediente do banco!',
  'bank.text.t4': '4. O tempo de pagamento é T+1, e o último pagamento é T+3 na Europa',
  'bank.text.t5': '5. Informe a plataforma antes de transferir dinheiro para confirmar se a conta está disponível. Se a conta for encerrada, nenhuma compensação será paga.',
  'bank.text.t6': '6. Depósito via cartão bancário. As informações de atendimento ao cliente devem prevalecer.',

  //lista bancária
  'bank.list.title': 'Nome do banco',
  'bank.list.code': 'Código do banco',
  'bank.list.amount': 'Valor da transferência',
  'bank.list.number': 'Número do recibo',
  'bank.list.img': 'Imagem do voucher',
  'bank.list.status': 'Status',
  'bank.list.time': 'hora',
  'bank.list.status1': 'Em análise',
  'bank.list.status2': 'Aprovado',
  'bank.list.status3': 'Rejeitado',

  // Cancelamento
  'withdrawal.address.desc': 'Por favor, insira o endereço da carteira!',
  'withdrawal.number': 'Número',
  'withdrawal.real.number': 'chegada real',
  'withdrawal.number.desc': 'Por favor, insira o valor do saque!',
  'withdrawal.btn.all': 'todos',
  'withdrawal.balance': 'Saldo',
  'withdrawal.commission': 'comissão',
  'withdrawal.actual.amount': 'Contabilidade',
  'withdrawal.notice': 'Prompt',
  'withdrawal.notice.text': 'Antes de transferir, confirme se as informações do endereço de recebimento estão corretas. Depois que os ativos forem transferidos, eles não poderão ser devolvidos.',
  'withdrawal.notice.content': '{name}({cp_name}) comissão: valor de mercado atual {fee_rate}%/pen, padrão mínimo: {fee_min} {name}/pen',
  'withdrawal.submit': 'Enviar',
  'withdrawal.choice': 'Escolha uma criptomoeda',
  'withdrawal.yzm': 'Código de verificação',
  'withdrawal.fs': 'Enviar',
  'withdrawal.qsryzm': 'Por favor, insira o código de verificação de e-mail',

  // recebe
  'recive.method': 'Protocolo',
  'receber.amount': 'Valor',
  'receber.address': 'Endereço',
  'receber.date': 'hora',
  'recive.status': 'Estado',
  'recive.create.at': 'Tempo de transação',
  'receber.type': 'Tipo',
  'receber.befor': 'antes da transação',
  'recive.balance': 'Saldo',
  'recive.freeze': 'Congelar',
  'recive.review': 'Revisando',
  'receber.success': 'Aprovado',
  'recive.reject': 'Rejeitado',

  // anunciar
  'advertise.title': 'Centro de promoções',
  'advertise.shop.title': 'Promoção da loja',
  'advertise.shop.status': 'Status',
  'advertise.shop.expired': 'Expirou',
  'advertise.shop.promotion': 'Promoção',
  'advertise.shop.expire.date': 'Data de vencimento',
  'advertise.shop.renew': 'Renovação de anúncio',
  'advertise.shop.payable': 'Valor a Pagar',
  'advertise.shop.explanation': 'Explicação do anúncio',
  'advertise.shop.text': 'Participe da promoção de publicidade da plataforma, aumente a exposição da loja e promova pedidos de transação',
  'advertise.shop.paynow': 'Renovar agora',
  'advertise.shop.modal.title': 'Confirmar pagamento',
  'advertise.shop.modal.desc': 'Confirmado para pagar a taxa de promoção',
  'advertise.shop.modal.btn': 'Confirmar Pagamento',

  //Lista de Desejos
  'lista de desejos.title': 'lista de desejos',
  'lista de desejos.delete': 'Excluir',
  'lista de desejos.pedidos': 'Vendas',

  //Seguir lista
  'followlist.title': 'Lista de seguidores',
  'followlist.delete': 'Excluir',
  'followlist.follow': 'Seguir',

  // loja
  'store.dashboard': 'Painel',
  'store.products': 'Produtos',
  'store.products.list': 'Lista de produtos',
  'store.products.reviews': 'Avaliações de produtos',
  'store.orders': 'Pedidos',
  'store.wallet': 'Carteira',
  'store.message': 'Central de Mensagens',
  'store.setting': 'configuração',
  'store.order.total.profit': 'Receita operacional total estimada',

  //painel
  'dashboard.store.hour.views': 'Visitas em tempo real',
  'dashboard.store.today.views': 'Visitas de hoje',
  'dashboard.product.total': 'Total do produto',
  'dashboard.product.today': 'Novo hoje',
  'dashboard.order.total': 'Pedido Total',
  'dashboard.sales.total': 'Venda estimada',
  'dashboard.sales.real': 'Vendas reais',
  'dashboard.sales.pay': 'Pagamento Total',
  'dashboard.sales.profit': 'Lucro Total',
  'dashboard.commission.total': 'Receita total',
  'dashboard.commission.today': 'Ganhos de hoje',
  'dashboard.order.sales': 'Vendas de mercadorias',
  'dashboard.sales.list': 'Classificação de vendas',
  'dashboard.goods.cate.rate': 'Relação de classificação do produto',
  'dashboard.goods.wish': 'Like product ranking',
  'dashboard.january': 'janeiro',
  'dashboard.february': 'fevereiro',
  'dashboard.março': 'março',
  'dashboard.april': 'abril',
  'dashboard.may': 'Maio',
  'dashboard.june': 'Junho',
  'dashboard.july': 'Julho',
  'dashboard.august': 'Agosto',
  'dashboard.setembro': 'Setembro',
  'dashboard.october': 'Outubro',
  'dashboard.november': 'Novembro',
  'dashboard.december': 'Dezembro',

  // produtos
  'products.add.new': 'Adicionar novo produto',
  'products.add.from.warehouse': 'Adicionar produtos do armazém',
  'products.delete': 'Excluir',
  'products.add': 'Adicionar',
  'products.table.img': 'imagem',
  'products.table.name': 'nome do produto',
  'products.table.category': 'Categoria',
  'products.table.wish': 'curtir',
  'products.table.stock': 'estoque',
  'products.table.price': 'Preço',
  'products.table.profit': 'Lucro',
  'products.table.action': 'Ação',
  'products.search.category': 'Pesquisar por categoria',
  'products.back.product': 'Voltar à lista de produtos',
  'products.total': 'Total de mercadorias',
  'products.yes': 'Sim',
  'products.no': 'Cancelar',
  'products.batch.add': 'Aumento de lote',
  'products.ask.add': 'Tem certeza que deseja adicionar produtos?',
  'products.batch.delete': 'Excluir lote',
  'products.ask.delete': 'Tem certeza que deseja excluir o produto?',
  'products.top': 'Top',
  'products.syzd': 'Topo da página inicial',
  'products.zdwz': 'Posição superior',
  'products.t1': 'Promoção',
  'products.t2': 'Preferido',
  'products.t3': 'Boutique',
  'produtos.t4': 'nenhum',

  // avaliações
  'reviews.title': 'Reviews do produto',
  'reviews.product.name': 'Produto',
  'reviews.user.name': 'Usuário',
  'reviews.star': 'Avaliação',
  'reviews.comment': 'conteúdo do comentário',
  'reviews.sku': 'Especificações do produto',
  'reviews.imgs': 'Atlas',
  'reviews.created': 'Data',

  // loja. pedido
  'store.order.purchase': 'Compre agora',
  'store.order.purchase.desc': 'Tem certeza que deseja pagar por este produto?',
  'store.order.purchase.yes': 'Pague agora',
  'store.order.purchase.no': 'Cancelar',
  'store.order.desc': 'A compra deste pedido requer pagamento e você receberá benefícios após a conclusão do pedido. ',
  'store.order.no': 'Número do pedido',
  'store.order.number': 'Quantidade',
  'store.order.buyer': 'Comprador',
  'store.order.total': 'Valor total',
  'store.order.will.earning': 'Receita',
  'store.order.profit': 'Lucro',
  'store.order.dividends': 'Dividendos',
  'store.order.payment.status': 'Status do Pagamento',
  'store.order.seller.buy.status': 'Status da compra',
  'store.order.status': 'Status do pedido',
  'store.order.date': 'data do pedido',
  'store.order.purchase.date': 'Data da compra',
  'store.order.action': 'Operação',
  'store.order.purchase.amount': 'Valor da compra',

  //renda
  'income.create.at': 'Tempo de registro',
  'income.order.sn': 'Número do pedido',
  'income.realpay': 'Valor do pedido',
  'receita.lucro': 'receita',

  //Contexto
  'setting.avatar': 'Avatar do negócio',
  'setting.upload': 'Carregar',
  'setting.shop.name': 'Nome da loja',
  'setting.shop.phone': 'Telefone de contato',
  'setting.shop.address': 'Endereço da loja',
  'setting.shop.save': 'Salvar',
  'setting.upload.pic': 'Carregar imagem',
  'setting.send.pic': 'Enviar foto',

  //vendedor
  'beseller.title': 'Inscreva-se para ser um comerciante',
  'beseller.account': 'Informações da conta',
  'beseller.store': 'Informações da loja',
  'beseller.store.name': 'Nome da loja',
  'beseller.store.address': 'Endereço da loja',

  //salvarcasa
  'store.home.title': 'Configurações da página inicial',
  'store.home.topimg': 'imagem superior',
  'store.home.banner': 'Imagem do carrossel',
  'store.home.up3': 'Observações: Pelo menos 3 uploads',
  'store.upload.more': 'Carregue mais imagens',
  'store.home.columns': 'Colunas',
  'store.home.bgimg': 'Imagem de fundo',
  'store.goods.remark': 'Observação: 5 itens por linha, 10 itens por prato no máximo',
  'store.home.select': 'Selecionar produto',
  'store.home.add': 'Adicionar coluna',

  //informações pessoais
  'setting.update.user': 'Informações pessoais',
  'setting.update.info': 'Modificar informações',
  'setting.user.avatar': 'Avatar do usuário',
  'setting.user.nickname': 'Apelido do usuário',
  'setting.user.nickname.desc': 'Por favor, digite o apelido do usuário',

  'setting.safe.title': 'Informações de segurança',
  'setting.user.passwd': 'Alterar senha',
  'setting.passwd.title1': 'Senha Antiga',
  'setting.passwd.desc1': 'Por favor, digite a senha antiga',
  'setting.passwd.title2': 'Nova Senha',
  'setting.passwd.desc2': 'Por favor, digite uma nova senha',
  'setting.passwd.title3': 'Confirmar senha',
  'setting.passwd.desc3': 'Por favor, digite uma senha de confirmação',
  'setting.invitation': 'Convidar amigos',
  'setting.copy': 'Copiar',

  'adv.add.products': 'Adicionar produtos promocionais',
  'adv.list.title': 'Promoção do produto',
  'adv.type': 'Tipo de promoção',
  'adv.begin.time': 'Tempo da promoção',
  'adv.end.time': 'Hora de término',
  'adv.status': 'Status',
  'adv.status.s1': 'Promovendo',
  'adv.status.s2': 'Fim',
  'adv.add.back': 'Retornar à lista de promovidos',
  'adv.recharge': 'Pontos de promoção de recarga',
  'adv.select.goods': 'Selecionar produtos',
  'adv.select.btn': 'Produtos Promovidos',
  'adv.recharge.title': 'Pontos de promoção de recarga',
  'adv.recharge.balance': 'Saldo de pontos',
  'adv.point': 'ponto',
  'adv.point.rate': 'Relação de pontos',
  'adv.recharge.amount': 'Valor da recarga',
  'adv.recharge.input.amount': 'Digite o valor da recarga',
  'adv.select.mod': 'Selecionar painel',
  'adv.mod1': 'Colunas recomendadas',
  'adv.mod2': 'Coluna preferida',
  'adv.mod3': 'Coluna de venda quente',
  'adv.mod4': 'Excelente lista',
  'adv.mod5': 'Lista de classificação',
  'adv.mod6': 'Promoção Detalhada',
  'adv.ge': 'Quantidade',
  'adv.xs': 'hora',
  'adv.xzbk': 'Selecionar prato',
  'adv.syme': 'Cota restante',
  'adv.tgsc': 'Duração da promoção',
  'adv.tgjg': 'Preço promocional',
  'adv.syye': 'Saldo atual',
  'adv.tgye': 'Saldo da promoção',
  'adv.yj1': 'esperado',
  'adv.yj2': 'Iniciar promoção',
  'adv.pay.point': 'Pontos de consumo',

  'wallet.ykcsxf': 'A taxa de manuseio foi deduzida',
  'wallet.sxf': 'Taxa de serviço',
  'wallet.zxje': 'Valor mínimo',
  'wallet.24hxe': 'limite de 24 horas',
  'wallet.desc.text': 'Sob nenhuma circunstância, o oficial não pedirá que você transfira dinheiro para uma conta, nem pedirá um código de verificação. Não participe de atividades ilegais, como compras em nome de terceiros, lavagem de dinheiro e arrecadação ilegal de fundos, e tome cuidado com fraudes on-line',
  'wallet.cunbi.text': 'Você só pode depositar {name} neste endereço, outros ativos não serão recuperados',

  'credit.title': 'Limite de Crédito',
  'credit.ed': 'Cota',
  'credit.hk': 'Reembolso',
  'credit.kyye': 'Saldo disponível',
  'credit.dqqk': 'Atraso atual',
  'credit.hkje': 'Valor do reembolso',
  'credit.hkje.desc': 'Insira o valor do reembolso',
  'credit.cgje': 'Valor da compra',
  'credit.qrz': 'Ir para autenticação',
  'credit.srxyj': 'Digite o valor do pagamento do crédito',

  'store.gzl': 'Quantidade de atenção',
  'store.wxz': 'Ilimitado',

  'auth.smrz': 'Autenticação de nome real',
  'auth.rzlx': 'Tipo',
  'auth.sfzm': 'frente do cartão de identificação',
  'auth.sffm': 'O verso do cartão de identificação',
  'auth.zsxm': 'nome real',
  'auth.zjhm': 'Número do documento',
  'auth.yyzz': 'Licença comercial',
  'auth.gsmc': 'Nome da empresa',
  'auth.shz': 'Auditoria',
  'auth.ytg': 'Aprovado',
  'auth.ybh': 'Rejeitado',
  'auth.zt': 'estado',
  'auth.gr': 'Pessoal',
  'auth.gs': 'Empresa',
  'auth.ljtj': 'Aplicar para autenticação',
  'auth.wrz': 'Não autenticado',

  'credit.qts': 'Ver descrição',
  'store.spss': 'Limite superior da mercadoria',
  'store.dpfh': 'Armazenar dividendos',
  'store.qydj': 'Nível de patrimônio',
  'store.level': 'nível',
  'store.jian': 'peças',

  'order.notify.email': 'Devido ao controle de risco de e-mail, os e-mails de lembrete de pedido da plataforma serão interceptados com frequência. Para recebê-los a tempo, é recomendável adicionar {email} à lista de permissões de e-mail de login',

  'setting.sjxx': 'Informações comerciais',
  'setting.smrz': 'Autenticação de nome real',
  'setting.aqzx': 'Central de Segurança',
  'setting.tzxx': 'Informações de notificação',
  'setting.dpzx': 'Página inicial de decoração',
  'setting.sjqy': 'Interesses comerciais',
  'setting.sjdjqy': 'Descrição dos benefícios de nível de negócios',

  'setting.fhbky': 'O dividendo atual não está disponível',
  'setting.spgzyzy': 'Regras e diretrizes da loja',

  'desc.dqqy': 'Patrimônio atual',
  'desc.xjqy': 'Interesses subordinados',
  'desc.sjqy_0': 'Limite de crédito da loja',
  'desc.sjqy_1': 'Armazenar dividendos',
  'desc.sjqy_2': 'Número de produtos que podem ser publicados',

  'share.yqlb': 'Lista de convites',
  'share.zmj': 'Vendedor total',
  'share.zdd': 'Pedido total',
  'share.zsy': 'Receita total',
  'share.wcl': 'não processado',
  'share.ycl': 'Processado',
  'share.ywc': 'Concluído',
  'share.dpmc': 'Nome da loja',
  'share.ddl': 'Quantidade do Pedido',
  'share.jjsy': 'Receita de corretagem',
  'share.zts': 'Número total',

  'chat.estado': 'estado',
  'chat.zx': 'On-line',
  'chat.ljz': 'Conectando',

  'bind.wallet.title': 'Endereço de retirada',
  'bind.address.title': 'Adicionar endereço',
  'bind.bj': 'Editar',
  'bind.sc': 'Excluir',
  'bind.qrsc': 'Tem certeza que deseja deletar este endereço?',
  'bind.qbdz': 'Endereço da carteira',
  'bind.tjsj': 'Adicionar horário',
  'bind.cz': 'Operação',
  'withdrawal.address.choice': 'Por favor, escolha o endereço de retirada!',

  'order.sxsj': 'Tempo efetivo',
  'order.ljsj': 'imediatamente efetivo',
  'order.dssj': 'Efetivo cronometrado',

  'store.st.zt': 'Status da loja',
  'store.st.zc': 'normal',
  'store.st.zc.desc': 'Sua loja está normal, por favor continue assim',
  'store.st.yc': 'Exceção',
  'store.st.yc.desc': 'Sua loja tem alguns pedidos anormais ou recebeu reclamações de clientes',
  'store.st.dj': 'Congelar',
  'store.st.dj.desc': 'Sua loja tem um certo risco operacional e os fundos estão congelados',
  'store.st.jy': 'desativado',
  'store.st.jy.desc': 'Sua loja violou os acordos e regras relacionados à plataforma e foi desativada',

  'task.sy': 'Página inicial',
  'task.rwlbo': 'Lista de tarefas',
  'task.rwxqo': 'Detalhes da tarefa',
  'task.ljcy': 'Participe agora',
  'task.gdrw': 'Mais tarefas',
  'task.rwlb': 'Lista de tarefas de captura',
  'task.qbrw': 'Todas as tarefas',
  'task.wcyd': 'Eu participei',
  'task.qdz': 'Obtendo pedidos',
  'task.ywc': 'Concluído',
  'task.djy': 'a ser negociado',
  'task.ddje': 'Valor do pedido',
  'task.cyrs': 'Número de participantes',
  'task.ewsy': 'Benefícios adicionais',
  'task.jzsj': 'Prazo',
  'task.ckxq': 'Ver detalhes',
  'task.rwxq': 'Detalhes da tarefa',
  'task.cylb': 'Lista de participantes',
  'task.qdcyrw': 'Tem certeza que deseja participar desta tarefa de pedido?',
  'task.sd': 'Sim',
  'task.bue': 'não',

  'task.wdsy': 'Receita',
  'task.wdfh': 'Dividendos',
  'task.wdqd': 'Obter pedido',
  'task.dd.leixin': 'Tipo de pedido',
  'task.dd.qdrw': 'Tarefa de pedido rápido',
  'task.dd.ptdd': 'Ordem comum',

  'flow.lltg': 'Promoção de fluxo',
  'flow.gmll': 'Comprar pacote de dados',
  'flow.dqtc': 'Pacote atual',
  'flow.tc1': 'Pacote',
  'flow.sy2': 'restante',
  'flow.tian3': 'dia',
  'flow.jlzs': 'Número total de registros',
  'flow.lltc': 'Pacote de fluxo',
  'fluxo.ll': 'fluxo',
  'flow.gmsj': 'Tempo de compra',
  'flow.tcjg': 'Preço do pacote',
  'flow.sjzf': 'pagamento real',
  'flow.tcsm': 'Descrição do pacote',
  'flow.tcsm.sm': 'A compra deste pacote aumentará a exposição da loja e obterá mais tráfego de clientes. A fim de obter mais pedidos e aumentar a receita da loja. ',

  'flow.tcmc': 'Nome do pacote',
  'flow.gmsc': 'Duração da compra',
  'flow.zje': 'Valor total',
  'flow.jzsj': 'Prazo',
  'flow.gmrq': 'Data de compra',
  'flow.tian1': 'dia',
  'flow.zhou1': 'semana',
  'flow.yue1': 'mês',

  'share.dj': 'nível',

  'share.hz': 'Transferir',
  'share.hzd': 'Transferir para',
  'share.qbye': 'saldo da carteira',
  'share.tgje': 'Valor da promoção',
  'share.hzje': 'Valor da transferência',
  'share.qsrhzje': 'Insira o valor da transferência',

  '_inv._title': 'Descrição do convite',
  '_inv._t1': '1. Convite e relação convidada',
  '_inv._desc1': 'A convida B, B convida C, C convida D. O nível mais alto é 4, se D convida E novamente, então E não tem nada a ver com A. ',
  '_inv._t2': '2. O convidador recebe uma recompensa',
  '_inv._desc2': 'A recebe 4% do valor total concluído pelo pedido B, A recebe 2% do valor total concluído pelo pedido C e A recebe 1% do valor total concluído pelo pedido D',

  '_index._gm': 'Comprar',
  '_index._sm': 'Descrição: Aumente a exposição da loja e atraia tráfego',

  '_n.dlyzc': 'Login e registro',
  '_n.hy4': 'Há mais 4',
  '_n.ckgd': 'Ver mais',
  '_n._cxhzk': 'Promoções e descontos',
  '_n._cwyp': 'Suprimentos para animais de estimação',
  '_n._tjsp': 'Oferta especial',
  '_n._rmfl': 'Categorias populares',
  '_n._tjsp2': 'Produtos recomendados',
  '_n._rxsp': 'Produtos recomendados',
  '_n._rmsp': 'Itens populares',
  '_n._gdsp': 'Mais produtos',
  '_n._yzsj': 'Comerciante de qualidade',

  '_n._zxsc': 'Shopping on-line',
  '_n._fkyys': 'Pagamento e Envio',
  '_n._xsgz': 'Regras de vendas',
  '_n._hhjth': 'Trocas e devoluções',
  '_n._lxwm': 'Entre em contato conosco',
  '_n._sczc': 'política do shopping',
  '_n._tkytj': 'Termos e Condições',
  '_n._tuzc': 'Política de Devolução',
  '_n._zczc': 'Política de Suporte',
  '_n._yszc': 'Política de Privacidade',
  '_n._scjj': 'Introdução ao shopping',
  '_n._ljgm': 'Compre agora',

  '_n2._rxsp': 'Itens mais vendidos',
  '_n2._pl': 'Comentário',
  '_n2._sjxx': 'Informações comerciais',
  '_n2._rhsh': 'Como receber mercadorias',
  '_n2._xsxd': 'Pedido on-line',
  '_n2._jscldd': 'Atendimento especializado',
  '_n2._mfps': 'Frete Grátis',
  '_n2._2xsfh': 'Entrega relâmpago',
  '_n2._1d5tdd': 'Chegada dentro de 1-5 dias',
  '_n2._tkzd': 'Correio Expresso',
  '_n2._ckfh': 'Enviar do Armazém',
  '_n2._zcps': 'Armazém próximo',
  '_n2._jsd': 'Ponto de recebimento',
  '_n2._qsjps': 'Entrega mundial',
  '_n2._thtj': 'Condições de devolução',
  '_n2._thtjsm': 'Você pode trocar ou devolver o produto no prazo de 14 dias a partir da data da compra',
  '_n2._gycp': 'Sobre o produto',
  '_n2._yc': 'Ocultar',
  '_n2._tiao': 'Tiao',
  '_n2._sypl': 'Todos os comentários',
  '_n2._lxfk': 'Deixe comentários',
  '_n2._xzspgg': 'Selecione as especificações do produto',
  '_n2._qxdl': 'Por favor, faça login primeiro',
  '_n2._rnsj': 'Como você pode ver',
  '_n2._rx': 'Venda quente',

  '_n3._qrdd': 'Confirmar pedido',
  '_n3._njsp': '{num} itens',
  '_n3._ddzf': 'Pagamento do pedido',
  '_n3._wydlsp': 'Bem-vindo ao fazer login na loja',

  '_n3._sjwfgm': 'O comerciante não pode comprar mercadorias',
  '_n3._sjbnlt': 'As empresas não podem conversar',
  '_chat._hc': 'Retirar',

  '_zc._sryxdz': 'Por favor, insira seu e-mail',
  '_zc._yxgscw': 'Erro no formato do e-mail',
  '_zc._qsrmm': 'Por favor, digite a senha',
  '_zc._lcmmbxd': 'Duas senhas são inconsistentes',
  '_zc._hdyzwtg': 'Por favor, verifique através do controle deslizante',
  '_zc._qsryqm': 'Por favor, insira o código de convite',
  '_zc._yhzh': 'Registrar conta de usuário',
  '_zc._qzc': 'Ir para registro',
  '_zc._srdpm': 'Por favor, insira o nome da loja',

  '_zc._gszz': 'Qualificação da empresa',
  '_zc._ptzz': 'Qualificações relacionadas à plataforma',

  '_dd._tijd': 'Enviando pedido',
  '_dd._xzdz': 'Por favor selecione o endereço de entrega',
  '_dd._zfz': 'Pagamento em andamento',

  '_wd._zf': 'pagamento',
  '_wd._cz': 'Recarregar',
  '_wd._txsq': 'Solicitação de retirada',
  '_wd._ddsy': 'Receita do pedido',
  '_wd._cgzf': 'Compra',
  '_wd._txbh': 'Rejeitar',
  '_wd._txtg': 'Aprovado',
  '_wd._ddtk': 'Reembolso do pedido',
  '_wd._yehz': 'Transferir',
  '_wd._gmll': 'Comprar tráfego',
  '_wd._zjkc': 'dedução do aluguel',
  '_wd._fk': 'Tudo bem',
  '_wd._ns': 'Imposto',
  '_wd._bzj': 'Margem',

  '_xxtz._zntz': 'Notificação do site',
  '_xxtz._fh': 'Retornar',
  '_xxtz._jzgd': 'Carregar mais',
  '_xxtz._mysj': 'Não mais',

  '_sjsy._jrdd': 'Pedido de hoje',
  '_sjsy._rxse': 'Vendas diárias',

  '_sjsy._xh': 'Índice',

  '_wd._nbjy': 'Transferência interna',
  '_wd._qsruid': 'Por favor, insira o UID do beneficiário',
  '_wd._je': 'Valor',
  '_wd._qsrhzje': 'Por favor, insira o valor da transferência',
  '_wd._skr': 'Beneficiário',
  '_wd._nbhzsm': 'Para transferir internamente para usuários da plataforma, verifique cuidadosamente as informações do beneficiário',

  '_st._spxlphb': 'Classificação de vendas do produto',
  '_st._czsp': 'Redefinir pesquisa',

  '_st._pljrgwc': 'Juntar todos',

  '_sj._yxz': 'Selecionado',
  '_sj._hjzje': 'Valor total',
  '_sj._zdcr': 'Depositar automaticamente',

  '_wd._ddfh': 'Dividendo',

  '_dd._csqx': 'Cancelamento de pedido',

  '_tx._yhktx': 'Retirada bancária',
  '_tx._sryhmc': 'Por favor insira o nome do banco',
  '_tx._srskrmc': 'Por favor, insira o nome do beneficiário',
  '_tx._srskzh': 'Insira o número da conta de pagamento',
  '_tx._sryhluh': 'Por favor, insira o número de identificação do banco',
  '_tx._sryhdm': 'Por favor, insira o código do banco',
  '_tx._sryhdz': 'Por favor, insira o endereço do banco',
  '_tx._txje': 'Valor da retirada',
  '_tx._srtxje': 'Por favor, insira o valor do saque',
  '_tx._txkc': 'Taxa de retirada {_sxf}%',

  '_yye._yyye': 'Vendas mensais',
  '_yye._ysy': 'Renda mensal',

  '_sj._hjzsy': 'Renda total',

  '_yhdl._dl': 'Login',
  '_yhdl._zc': 'Criar conta',

  '_zdy._24zrkf': 'Estamos aqui para ajudá-lo 24 horas por dia, 7 dias por semana',
  '_zdy._zhsz': 'Configurações da conta',
  '_zdy._mfps': 'Por tempo limitado, você pode obter frete grátis em 2 dias úteis para milhares de itens em áreas selecionadas. ',
  '_zdy._gmsp': 'Comprar novos produtos',
  '_zdy._czyh': 'Ótimas ofertas, aproveite seus produtos favoritos',
  '_zdy._xsgw': 'Compras por tempo limitado! Faça o pedido antes de 12 de maio ao meio-dia ET para frete grátis. Entregue na sua porta por um carro dedicado! ',
  '_zdy._ssgjc': 'Insira a pesquisa por palavra-chave',

  '_zdy._rmjx': 'Suas principais escolhas',

  '_dd._plcz': 'Operação em lote',
  '_dd._plcg': 'Compra em massa',
  '_dd._qgxcgd': 'Por favor, verifique o pedido que você precisa comprar',
  '_dd._yxddbcg': 'O pedido selecionado não requer compra',
  '_dd._qdyplcgm': 'Tem certeza de que deseja comprar o pedido selecionado em massa? ,Quantidade:{n}',
  '_dd._qdcg': 'Confirmar compra',
  '_dd._qx': 'Cancelar',
  '_dd._plcgwc': 'Compra em lote concluída, bem-sucedida: {s}/{t}',
  '_dd._zzcgz': 'Compras',
  '_st._czhk': 'Recarregar e pagar',
  '_st._dkje': 'montante do empréstimo',
  '_st._dkjl': 'histórico de empréstimo',
  '_st._hkfs': 'Reembolso',
  '_st._hkje': 'Valor do reembolso',
  '_st._jrong': 'Finanças de shopping',
  '_st._ljsq': 'aplicar imediatamente',
  '_st._qrshkje': 'Por favor insira o valor do reembolso',
  '_st._qsrdkje': 'Por favor insira o valor do empréstimo',
  '_st._shz': 'sob revisão',
  '_st._sqdklx': 'empréstimo',
  '_st._sqhk': 'Solicitar reembolso',
  '_st._sqhklx': 'reembolso',
  '_st._sqjk': 'aplicar para um emprestimo',
  '_st._sqlx': 'tipo',
  '_st._ybh': 'rejeitar',
  '_st._yjk': 'Emprestado',
  '_st._ytg': 'passar',
  '_st._zzhk': 'Reembolso pendente',

  '_st._dqed': 'Valor do empréstimo',
  '_st._ccdked': 'Não pode exceder o limite do empréstimo',
  '_st._tjsqz': 'A inscrição está sendo enviada',
  '_st._dklx': 'Juros',
  '_st._dkll': 'Taxa de juros do empréstimo:{n}%',
  '_kdts._ts': 'Aberto por {t} dias',
  '_jltg._tgje': 'Investimento publicitário',

  '_st._yqk': 'Atraso',
  '_st._qb': 'Todos',

  '_spxx._spbm': 'código',

  '_sjdhb._hbbt': 'Utilizar voucher do cartão',
  '_sjdhb._wsy': 'Não utilizado',
  '_sjdhb._ysy': 'Usado',
  '_sjdhb. _ygq': 'Expirado',
  '_sjdhb._hbje': 'Valor do cupão',
  '_sjdhb._gqsj': 'Tempo de expiração',
  '_sjdhb._lqsj': 'Hora da recolha',
  '_sjdhb._hbdk': 'Dedução de cupão',
  '_sjdhb._xzhb': 'Selecionar cupão',
  '_sjdhb._sjzf': 'Pagamento real',
  '_sjdhb._hmyzh': 'Ainda não há conta',
  '_sjdhb._zcblq': 'Registe-se e receba',
  '_sjdhb. _dlyx': 'E-mail de login',
  '_sjdhb. _sryx': 'Por favor, introduza o e-mail',
  '_sjdhb._szmm': 'Definir palavra-passe',
  '_sjdhb._srmm': 'Por favor, introduza a palavra-passe',
  '_sjdhb._lxdh': 'Número de contacto',
  '_sjdhb._srlxdh': 'Por favor, introduza o número de contacto',
  '_sjdhb._yzm': 'Código de verificação',
  '_sjdhb._sryxyzm': 'Por favor, introduza o código de verificação de e-mail',
  '_sjdhb._ljlq': 'Receba já'
}
