import auth from './auth'
import user from './user'
import code from './code'
import home from './home'
import category from './category'
import goods from './goods'
import order from './order'
import orderTask from './order_task'
import store from './store'
import currency from './currency'
import storeInfo from './store_info'
import chat from './chat'
import docs from './docs'
import tools from './tools'
import flow from './flow'
import notice from './notice'
import finance from './finance'
import redbag from './redbag'
export default {
  auth,
  user,
  code,
  home,
  category,
  goods,
  order,
  orderTask,
  store,
  currency,
  storeInfo,
  chat,
  docs,
  tools,
  flow,
  notice,
  finance,
  redbag
}
