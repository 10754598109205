/**-----------------------------------------------*/
/* 商家
/* -----------------------------------------------*/

import StoreSignin from '../view/store/signin.vue'

import MyStore from '../view/store/mystore.vue'
import StoreLayout from '../view/store/layout.vue'

//首页
import storeDashboard from '../view/store/dashboard.vue'

//产品
import StoreProductsList from '../view/store/products/products.vue'
import StoreAddFromHouse from '../view/store/products/addFromHouse.vue'
import storeProductsReviews from '../view/store/products/reviews.vue'

//订单
import StoreOrderList from '../view/store/order/list.vue'
import StoreOrderDetail from '../view/store/order/detail.vue'

import StoreWallet from '../view/store/wallet.vue'

//红包
import StoreRedbag from '../view/store/redbag.vue'

//金融
import StoreFinance from '../view/store/finance.vue'

//产品
import StoreAdvertiseFlow from '../view/store/advertise/flow.vue'
import StoreAdvertiseList from '../view/store/advertise/index.vue'
import StoreAdvertiseAdd from '../view/store/advertise/add.vue'

// import StoreSetting from '../view/web1/store/setting'
import StoreStore from '../view/store/setting/store'
import StoreSafety from '../view/store/setting/safety'
import StoreAuth from '../view/store/setting/auth'
import StoreNotify from '../view/store/setting/notify'
import storeMyHome from '../view/store/setting/home'
import storeBenefit from '../view/store/setting/benefit'
import storeRule from '../view/store/setting/rule'
import StoreMessage from '../view/store/message.vue'
import StoreNotice from '../view/store/notice/notice.vue'
import StoreNoticeDetail from '../view/store/notice/detail.vue'
import StoreShare from '../view/store/share/share.vue'
import StoreShareList from '../view/store/share/list.vue'

const Router = [
  {
    path: '/store/signin',
    name: 'storeSignin',
    meta: { isLogin: false },
    component: StoreSignin
  },
  {
    path: '/mystore',
    meta: { isLogin: true, type: 2 },
    component: MyStore,
    children: [
      {
        path: '/store/dashboard',
        name: 'storeDashboard',
        meta: { name: 'storeDashboard' },
        component: storeDashboard
      },
      {
        path: '/store/products',
        meta: { name: 'storeProducts' },
        component: StoreLayout,
        children: [
          {
            path: '/store/products/list',
            name: 'storeProductsList',
            component: StoreProductsList
          },
          {
            path: '/store/products/addfromhouse',
            name: 'StoreAddFromHouse',
            meta: { key: 'storeProductsList' },
            component: StoreAddFromHouse
          },
          {
            path: '/store/products/reviews',
            name: 'storeProductsReviews',
            component: storeProductsReviews
          }
        ]
      },
      {
        path: '/store/wallet',
        name: 'storeWallet',
        meta: { name: 'storeWallet' },
        component: StoreWallet
      },
      {
        path: '/store/redbag',
        name: 'storeRedbag',
        meta: { name: 'storeRedbag' },
        component: StoreRedbag
      },
      {
        path: '/store/finance',
        name: 'storeFinance',
        meta: { name: 'storeFinance' },
        component: StoreFinance
      },
      {
        path: '/store/order',
        meta: { name: 'storeOrder' },
        component: StoreLayout,
        children: [
          {
            path: '/store/order/list',
            name: 'storeOrderList',
            component: StoreOrderList
          },
          {
            path: '/store/order/detail',
            name: 'storeOrderDetail',
            meta: { key: 'storeOrderList' },
            component: StoreOrderDetail
          }
        ]
      },
      // {
      //   path: "/store/advertise",
      //   name: "storeAdvertise",
      //   meta: { name: "storeAdvertise" },
      //   component: StoreAdvertise,
      // },
      {
        path: '/store/advertise',
        meta: { name: 'storeAdvertise' },
        component: StoreLayout,
        children: [
          {
            path: '/store/advertise/list',
            name: 'storeAdvertiseList',
            component: StoreAdvertiseList
          },
          {
            path: '/store/advertise/add',
            name: 'storeAdvertiseAdd',
            meta: { key: 'storeAdvertiseList' },
            component: StoreAdvertiseAdd
          },
          {
            path: '/store/advertise/flow',
            name: 'storeAdvertiseFlow',
            component: StoreAdvertiseFlow
          }
        ]
      },
      {
        path: '/store/message',
        name: 'storeMessage',
        meta: { name: 'storeMessage' },
        component: StoreMessage
      },
      {
        path: '/store/notice',
        name: 'notice',
        meta: { name: 'notice' },
        component: StoreLayout,
        children: [
          {
            path: '/store/notice/index',
            name: 'storeNotice',
            component: StoreNotice
          },
          {
            path: '/store/notice/detail',
            name: 'storeNoticeDetail',
            component: StoreNoticeDetail
          }
        ]
      },
      {
        path: '/store/share',
        name: 'share',
        meta: { name: 'share' },
        component: StoreLayout,
        children: [
          {
            path: '/store/share',
            name: 'storeShare',
            component: StoreShare
          },
          {
            path: '/store/share/list',
            name: 'storeShareList',
            component: StoreShareList
          }
        ]
      },
      {
        path: '/store/setting',
        name: 'storeSetting',
        meta: { name: 'storeSetting' },
        component: StoreLayout,
        children: [
          {
            path: '/setting/store',
            name: 'storeStore',
            component: StoreStore
          },
          {
            path: '/setting/auth',
            name: 'storeAuth',
            component: StoreAuth
          },
          {
            path: '/setting/safety',
            name: 'storeSafety',
            component: StoreSafety
          },
          {
            path: '/setting/notify',
            name: 'storeNotify',
            component: StoreNotify
          },
          {
            path: '/setting/home',
            name: 'storeMyHome',
            component: storeMyHome
          },
          {
            path: '/setting/benefit',
            name: 'storeBenefit',
            component: storeBenefit
          },
          {
            path: '/setting/rule',
            name: 'storeRule',
            component: storeRule
          }
        ]
      }
    ]
  }
]

export default Router
