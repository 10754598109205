<template>
  <footer role="contentinfo" class="b bR" style="background: #fff; padding-top: 30px">
    <div class="bU">
      <nav>
        <ul class="hr">
          <li class="ht">
            <span class="hx">{{ $t('_n._zxsc') }}</span>
            <ul class="l_1 hy">
              <li class="oS o_9 o_2 oX hz oQ">
                <router-link :to="{ name: 'docs', query: { key: 'transport' } }" class="oR">
                  <div class="oW">{{ $t('_n._fkyys') }}</div>
                </router-link>
              </li>
              <li class="oS o_9 o_2 oX hz oQ">
                <router-link :to="{ name: 'docs', query: { key: 'sale' } }" class="oR">
                  <div class="oW">{{ $t('_n._xsgz') }}</div>
                </router-link>
              </li>
              <li class="oS o_9 o_2 oX hz oQ">
                <router-link :to="{ name: 'qualification' }" class="oR">
                  <div class="oW">{{ $t('_zc._gszz') }}</div>
                </router-link>
              </li>
            </ul>
          </li>
          <li class="ht">
            <span class="hx">{{ $t('_n._lxwm') }}</span>
            <ul class="l_1 hy">
              <!-- <li class="oS o_9 o_2 oX hz oQ">
                <a href="javascript:" class="oR" rel="nofollow"
                  ><div class="oW">{{ $t('footer.fwemail') }}:service@supergbf.com</div></a
                >
              </li> -->
              <li class="oS o_9 o_2 oX hz oQ">
                <a href="javascript:" class="oR"
                  ><div class="oW">{{ $t('footer.gfemail') }}：redisshop@redisshop.com</div></a
                >
              </li>
              <li class="oS o_9 o_2 oX hz oQ">
                <router-link :to="{ name: 'docs', query: { key: 'about_us' } }" class="oR">
                  <div class="oW">{{ $t('footer.about.us') }}</div>
                </router-link>
              </li>
            </ul>
          </li>
          <li class="ht">
            <span class="hx">{{ $t('_n._sczc') }}</span>
            <ul class="l_1 hy">
              <li class="oS o_9 o_2 oX hz oQ">
                <router-link :to="{ name: 'docs', query: { key: 'condition' } }" class="oR">
                  <div class="oW">{{ $t('_n._tkytj') }}</div>
                </router-link>
              </li>
              <li class="oS o_9 o_2 oX hz oQ">
                <router-link :to="{ name: 'docs', query: { key: 'exchange' } }" class="oR">
                  <div class="oW">{{ $t('_n._tuzc') }}</div>
                </router-link>
              </li>
              <li class="oS o_9 o_2 oX hz oQ">
                <router-link :to="{ name: 'docs', query: { key: 'support' } }" class="oR">
                  <div class="oW">{{ $t('_n._zczc') }}</div>
                </router-link>
              </li>
              <li class="oS o_9 o_2 oX hz oQ">
                <router-link :to="{ name: 'docs', query: { key: 'privacy' } }" class="oR">
                  <div class="oW">{{ $t('_n._yszc') }}</div>
                </router-link>
              </li>
            </ul>
          </li>
          <li class="ht">
            <span class="hx">{{ $t('_n._scjj') }}</span>
            <ul class="l_1 hy">
              <li class="oS o_9 o_2 oX hz oQ">
                <a href="javascript:" class="oR"
                  ><div class="oW">{{ $t('footer.great.value') }}</div></a
                >
              </li>
              <li class="oS o_9 o_2 oX hz oQ">
                <a href="javascript:" class="oR"
                  ><div class="oW">{{ $t('footer.shopping') }}</div></a
                >
              </li>
              <li class="oS o_9 o_2 oX hz oQ">
                <a href="javascript:" class="oR"
                  ><div class="oW">{{ $t('footer.safe.payment') }}</div></a
                >
              </li>
              <li class="oS o_9 o_2 oX hz oQ">
                <a href="javascript:" class="oR"
                  ><div class="oW">{{ $t('footer.shop.with.confidence') }}</div></a
                >
              </li>
              <li class="oS o_9 o_2 oX hz oQ">
                <a href="javascript:" class="oR"
                  ><div class="oW">{{ $t('footer.help.center') }}</div></a
                >
              </li>
              <li class="oS o_9 o_2 oX hz oQ">
                <router-link :to="{ name: 'beseller' }" href="javascript:" class="oR">
                  <div class="_be_seller">{{ $t('footer.be.seller') }}</div>
                </router-link>
              </li>
            </ul>
          </li>
        </ul>
      </nav>
      <section class="rO">
        <div style="width: 100%">
          <div class="rQ">
            <div class="rR">
              <div class="cj">
                <div class="cl">
                  <a href="/" class="ju jv" data-testid="logo_link"></a>
                </div>
              </div>
            </div>
          </div>
          <div class="rT1">
            <div class="rU">
              <div class="foot-sns-box" style="display: flex; gap: 10px">
                <div class="rV rW a-download">
                  <div class="app-download">
                    <img src="../../assets/app_android.png" alt="" />
                  </div>
                </div>
                <div class="rV rX a-download">
                  <div class="app-download">
                    <img src="../../assets/app_ios.png" alt="" />
                  </div>
                </div>
              </div>
              <!-- <a class="rV rY" href="javascript:" title="AppGallery" target="_blank" rel="noopener noreferrer">
                <span class="rZ">AppGallery</span>
              </a> -->
            </div>
            <div class="st">
              <ul class="r_4">
                <li class="r_5">
                  <a class="r_0 fb" href="javascript:"></a>
                </li>
                <li class="r_5">
                  <a class="r_0 twitter" href="javascript:"></a>
                </li>
                <li class="r_5">
                  <a class="r_0 instagram" href="javascript:"></a>
                </li>
                <li class="r_5">
                  <a class="r_0 messenger" href="javascript:"></a>
                </li>
                <li class="r_5">
                  <a class="r_0 whatapp" href="javascript:"></a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </section>
    </div>
  </footer>
</template>

<script>
export default {
  name: 'FooterVue'
}
</script>

<style scoped>
._be_seller {
  display: inline-block;
  background-image: linear-gradient(94deg, #2196F3, #2196F3);
  border-radius: 5px;
  font-size: 13px;
  color: #fff;
  cursor: pointer;
  padding: 9px 15px;
}

._be_seller:hover {
  background-image: linear-gradient(94deg, #147fd7, #147fd7);
}

a.fb {
  background: url('@/static/img/fb.png') no-repeat 0 0;
  background-size: cover;
  opacity: 0.8;
}

a.fb:hover {
  opacity: 1;
}

a.twitter {
  background: url('@/static/img/twitter-x.png') no-repeat;
  background-size: cover;
  opacity: 0.8;
}

a.twitter:hover {
  opacity: 1;
}

a.instagram {
  background: url('@/static/img/instagram.png') no-repeat;
  background-size: cover;
  opacity: 0.8;
}

a.instagram:hover {
  opacity: 1;
}

a.messenger {
  background: url('@/static/img/messenger.png') no-repeat;
  background-size: cover;
  opacity: 0.8;
}

a.messenger:hover {
  opacity: 1;
}

a.whatapp {
  background: url('@/static/img/whatapp.png') no-repeat;
  background-size: cover;
  opacity: 0.8;
}

a.whatapp:hover {
  opacity: 1;
}

.rT1 {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

@media only screen and (min-width: 600px) {
  .cj .cl {
    height: 84px;
  }
}

@media only screen and (min-width: 1024px) {
  .cj .cl,
  .cj .cl.cm {
    padding-left: 0px !important;
    padding-right: 0px !important;
  }
}
.cj .cl {
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-sizing: border-box;
  width: 100%;
  max-width: 1480px;
  padding-left: 12px;
  padding-right: 12px;
  margin-left: auto;
  margin-right: auto;
  height: 64px;
}

.app-download {
  position: absolute;
  width: 170px;
  height: 170px;
  z-index: 10;
  bottom: 100%;
  border: 2px solid #ff5722;
  display: none;
}
.app-download img {
  width: 100%;
  height: 100%;
}
.a-download {
  flex: 1;
  position: relative;
}
.a-download:hover .app-download {
  display: block;
}
</style>
<style>
.ju.jv {
  width: 280px;
  height: 43px;
  background-size: 100%;
}

.ju.jv {
  /* background-image: url('@/static/svg/logo.svg'); */
  background-image: url('@/static/logo.png');
  background-size: 280px 43px;
}
.ju,
.ju.jv {
  background-position: 0;
}
.ju {
  background-size: contain;
  background-repeat: no-repeat;
}
</style>
