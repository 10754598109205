<template>
  <a-table :columns="columns" :data-source="dataSource" :pagination="pagination" :loading="loading" @change="getRedbagRecord" style="width: 100%">
    <template #bodyCell="{ column, record }">
      <!-- <template v-if="record && column.title === 'Name'">
        <div style="display:flex;align-items: center;">
          <img :src="record.img" width="40" style="margin-right:5px" />
          <div style="font-size:13px">{{record.name}}</div>
        </div>
      </template>
      <template v-if="record && column.title === 'Price'">
        <div>${{record.price}}</div>
      </template>-->
      <template v-if="record && column.key === 'is_use'">
        <a-tag :color="statusType(record.is_use).color">{{ statusType(record.is_use).text }}</a-tag>
      </template>
    </template>
  </a-table>
</template>

<script>
export default {
  name: 'ReceiveList',
  data() {
    return {
      //存币记录表格
      columns: [
        {
          title: this.$t('_sjsy._xh'),
          customRender: (record) => {
            if (!this.loading) {
              return (this.pagination.current - 1) * this.pagination.pageSize + record.index + 1
            }
          },
          width: '10%'
        },
        {
          title: this.$t('_sjdhb._hbje'),
          customRender: (item) => {
            return '$ ' + item.record.amount
          },
          width: '40%'
        },
        {
          title: this.$t('bank.list.status'),
          key: 'is_use',
          width: '10%'
        },
        {
          title: this.$t('_sjdhb._gqsj'),
          dataIndex: 'expired_time',
          width: '20%'
        },
        {
          title: this.$t('_sjdhb._lqsj'),
          dataIndex: 'create_time',
          width: '20%'
        }
      ],
      status: {
        1: {
          text: this.$t('_sjdhb._wsy'),
          color: 'green'
        },
        2: {
          text: this.$t('_sjdhb._ysy'),
          color: 'orange'
        },
        3: {
          text: this.$t('_sjdhb._ygq'),
          color: 'red'
        }
      },
      loading: false,
      pagination: { total: 0, current: 1, pageSize: 10, is_use: 2 },
      dataSource: [],
      queryParam: {}
    }
  },
  created() {
    this.getRedbagRecord(this.pagination)
  },
  methods: {
    refresh() {
      this.getRedbagRecord({ total: 0, current: 1, pageSize: 10, is_use: 2 })
    },
    statusType(status) {
      return this.status[status]
    },
    getRedbagRecord(parameter) {
      const pagination = {
        page: parameter.current,
        size: parameter.pageSize
      }
      const requestParameters = Object.assign({ is_use: parameter.is_use }, pagination, this.queryParam)

      this.loading = true

      let that = this
      this.$api.redbag.getMyRedbagList(requestParameters).then((res) => {
        that.loading = false
        that.pagination = {
          total: res.data.total,
          current: res.data.pageNo,
          pageSize: res.data.pageSize
        }
        that.dataSource = res.data.data
      })
    }
  }
}
</script>

<style></style>
